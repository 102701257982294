<template>
  <div class="widgetContainer--center widgetContainer--scrollable widgetContainer pilot__accounts">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          Login As
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div style="font-size: 20px; font-weight: bold; margin-bottom: .5rem">
        Default Account
      </div>
      <el-card class="person_card mb-8" @click.native="kycStatusFlow">
        <div class="initialsInfoContact initialCaps">
          <span class="el-icon-user-solid" />
        </div>
        <div class="details">
          <p class="name initialCaps">
            Default Account
          </p>
        </div>
        <span class="icon-Arrow-small-right icon" />
      </el-card>
      <div style="font-size: 20px; font-weight: bold; margin-bottom: .5rem">
        As Manager
      </div>
      <el-card class="person_card mb-1" v-for="account in pilotAccounts" :key="account.id" @click.native="actAsManager(account)">
        <div class="initialsInfoContact initialCaps">
          <span class="el-icon-user" />
        </div>
        <div class="details">
          <p class="name initialCaps">
            {{ account.owner.name }}
          </p>
          <p class="email">
            {{ account.owner.email }}
          </p>
        </div>
        <span class="icon-Arrow-small-right icon" />
      </el-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import loginMixin from '@/modules/user/mixins/login'
import Vue from 'vue';

export default {
  mixins: [loginMixin],
  name: 'PilotAccounts',
  computed:{
    ...mapGetters({
      'pilotAccounts': 'user/getPilotAccounts'
    }),
  },
  methods:{
    async actAsManager(account){
      const loader = this.showLoader();
      this.$store.commit('user/setPilot',account)
      this.$store.commit('user/setPilotUser',this.$store.getters['user/getPersonDetails'])
      await this.$store.dispatch('user/getPerson')
      this.$store.commit('user/setUserDetails',{phone: this.$store.getters['user/getPersonDetails'].phone})
      Vue.$serverRequest.defaults.headers.common['scapital-piloting'] = account.id;
      this.kycStatusFlow();
      loader.close()
    }
  }
}
</script>

<style lang="scss">
.pilot__accounts{
  .person_card {
    .el-card__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      transition: 0.2s;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;

      .initialsInfoContact {
        background: #f6f7f9;
        color: var(--branding-primary);
      }

      .details {
        margin: 0 auto 0 15px;
        padding-right: 8px;

        .name {
          font-size: 15px;
          line-height: 20px;
        }

        .email {
          color: rgba(#3c3c43, 0.6);
          font-size: 12px;
          padding-top: 2px;
        }
      }

      .icon {
        color: #c7c7c9;
        font-size: 13px;
      }
    }
    &:hover{
      cursor: pointer;
      background: var(--shifl-light-grey);
    }
  }
}
</style>